<template>
  <!-- Navbar -->
  <nav class="main-header navbar p-1 navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#"><i class="fa fa-bars"></i></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="/home" class="nav-link">Home</a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <router-link to="/admin/sugestions">
          <a class="nav-link" href="#">Sugestões</a>
        </router-link>
      </li>
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Notifications Dropdown Menu -->
      <li class="nav-item">

        <div class="dropdown">

          <button style="color: #0007" class="btn dropdown-toggle div-signout d-flex align-items-center"
                  type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false">
              <span class="div-image mr-2 d-flex justify-content-center align-items-center">
                <img src="@/assets/images-logo/tallos_logo.svg" alt="User Image" class="image-user">
              </span>
            <span class="d-flex flex-column">
                <span class="mr-3 p-0">{{ nameAdmin }}</span>
                <small class="mr-3">{{ profileAdmin }}</small>
              </span>
          </button>

          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
            <router-link tag="a" :to="profileHef"
                         class="d-flex align-items-center justify-content-center icon-button"
                         title="Perfil">
              Perfil
              <i class="fa fa-user-alt ml-1"></i>
            </router-link>

            <router-link tag="a" to="/"
                         class="d-flex align-items-center justify-content-center icon-button"
                         title="Sair">
              Sair
              <i class="fa fa-sign-out-alt ml-1"></i>
            </router-link>
          </div>
        </div>

      </li>
    </ul>
  </nav>
  <!-- /.navbar -->
</template>

<script>
export default {

  data() {
    return {
      nameAdmin: '',
      profileAdmin: '',
      profileHef: ''
    }
  },

  mounted() {

    setTimeout(() => {
      if (this.$store.state.admin) {
        this.profileHef = `/admin/profile/${this.$store.state.admin.id}`
        this.nameAdmin = this.$store.state.admin.name
        switch (this.$store.state.admin.perfil) {
          case 'admin':
            this.profileAdmin = 'Administrador'
            break;
          case 'seller':
            this.profileAdmin = 'Vendedor'
            break;
          case 'author':
            this.profileAdmin = 'Autor'
            break;
          case 'support':
            this.profileAdmin = 'Suporte'
            break;
          case 'finance':
            this.profileAdmin = 'Financeiro'
            break;
        }
      } else {
        this.nameAdmin = 'Tallos Admin'
      }
    }, 700)
  }
}
</script>

<style scoped>
.div-signout {
  padding: 2px;
}

.icon-button {
  color: #6c757d;
}

.icon-button:hover {
  background-color: #6c757d;
  color: #ffffff;
}

.div-image {
  background-color: rgb(24, 24, 24);
  border-radius: 13px;
  width: 35px;
  height: 35px;
}

.image-user {
  width: 25px;
  height: 25px;
}
</style>